import * as React from "react"
import './location-specifics-section.scss'
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { useViewport } from '../hooks/useViewPort'
import Plx from 'react-plx';

export default function LocationSpecificsSection() {

    const data = useStaticQuery(
        graphql`
        query LocationSpecificsSection {
            TopSpecificsSection: wordPressAcfBlockImageText(
                header: {eq: "Top Location Specifics Section"}
              ) {
                id
                header
                text
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                  caption
                }
              }
              TopSpecificsSectionMobile: wordPressAcfBlockImageText(
                header: {eq: "Top Location Specifics Section"}
              ) {
                image_mobile {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
              BottomSpecificsSection: wordPressAcfBlockImageText(
                header: {eq: "Bottom Location Specifics Section"}
              ) {
                id
                header
                text
                image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                    }
                    caption
                  }
              }
              BottomSpecificsSectionMobile: wordPressAcfBlockImageText(
                header: {eq: "Bottom Location Specifics Section"}
              ) {
                image_mobile {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED)
                      }
                    }
                  }
              }
        }
    `
    )




    const { width } = useViewport();
    const breakpointMobile = width < 426;

    const enterViewVertical = [
        {
            start: "self",
            end: "self",
            endOffset: "50vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: 350,
                    endValue: 0,
                    property: 'translateY',
                },
            ],
        },
    ];

    const enterViewVerticalText = [
        {
            start: "self",
            end: "self",
            endOffset: "50vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: 350,
                    endValue: 0,
                    property: 'translateY',
                },
            ],
        },
    ];

    const enterViewVerticalMobile = [
        {
            start: "self",
            end: "self",
            endOffset: "25vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: 100,
                    endValue: 0,
                    property: 'translateY',
                },
            ],
        },
    ];


    return (
        <>
            <section className='outer-specifics-container'>
                <div className='inner-location-specifics-container'>
                    <div className='top-specific'>
                        <Plx
                            animateWhenNotInViewport={true}
                            parallaxData={!breakpointMobile ? enterViewVertical : enterViewVerticalMobile}
                        >
                            <div className='num-img-container'>
                                <div className='img-container'>
                                    <div className='inner-image-container'>
                                        <GatsbyImage className="firstLocationImg" image={data.TopSpecificsSection.image.localFile.childImageSharp.gatsbyImageData} alt={data.TopSpecificsSection.image.title} />
                                    </div>
                                    <p
                                        dangerouslySetInnerHTML={{ __html: data.TopSpecificsSection.image.caption }}
                                    />
                                </div>
                                <div className='img-num'>01.</div>
                            </div>
                        </Plx>
                        <Plx
                            animateWhenNotInViewport={true}
                            parallaxData={!breakpointMobile ? enterViewVerticalText : enterViewVerticalMobile}
                        >
                            <div className='text-container'>
                                <p>{data.TopSpecificsSection.text}</p>
                            </div>
                        </Plx>
                    </div>
                    <div className='bottom-specific'>
                        <Plx
                            animateWhenNotInViewport={true}
                            parallaxData={!breakpointMobile ? enterViewVertical : enterViewVerticalMobile}
                        >
                            <div className='num-img-container'>
                                <div className='img-container'>
                                    <div className='inner-image-container'>
                                        <GatsbyImage className="secondLocationImg" image={data.BottomSpecificsSection.image.localFile.childImageSharp.gatsbyImageData} alt={data.BottomSpecificsSection.image.title} />
                                    </div>
                                    <p
                                        dangerouslySetInnerHTML={{ __html: data.BottomSpecificsSection.image.caption }}
                                    />
                                </div>
                                <div className='img-num'>02.</div>
                            </div>
                        </Plx>
                        <Plx
                            animateWhenNotInViewport={true}
                            parallaxData={!breakpointMobile ? enterViewVerticalText : enterViewVerticalMobile}
                        >
                            <div className='text-container'>
                                <p>{data.BottomSpecificsSection.text}</p>
                            </div>
                        </Plx>
                    </div>
                </div>
            </section>

        </>
    )
}