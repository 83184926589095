import * as React from "react"
import './events-section.scss'
import ArrowRight from '../images/icons/arrow-events.svg'
import { useViewport } from '../hooks/useViewPort'
import { useStaticQuery, graphql } from "gatsby"
import Plx from 'react-plx';
import AniLink from "gatsby-plugin-transition-link/AniLink"
import EventContainerHp from './event-container-hp'

export default function EventsSection() {

    const data = useStaticQuery(
        graphql`
        query EventsSection {
            eventsSection: allWordpressWpKoeEvent(
                limit: 3
                sort: {fields: acf___koe_start_date, order: ASC}
                ) {
                edges {
                  node {
                    koe_eventcategory
                    acf {
                      koe_all_day_event
                      koe_button_link
                      koe_button_text
                      koe_category
                      koe_company
                      koe_end_time
                      koe_event_name   
                      koe_header_text
                      koe_start_date(formatString: "YYYY-MM-DD")
                      koe_start_time   
                      koe_teaser_text
                      koe_teaser_image {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(layout: CONSTRAINED)
                          }
                        }
                      }
                    }
                    id
                    title
                  }
                }
              }
        }
    `
    )

    const { width } = useViewport();
    const breakpointMobile = width < 426;

    const slideInDataHeader = [
        {
            start: "self",
            end: "self",
            endOffset: "50vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: -500,
                    endValue: 0,
                    property: 'translateX',
                },
            ],
        },
    ];

    const enterViewVertical = [
        {
            start: "self",
            end: "self",
            endOffset: "50vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: 350,
                    endValue: 0,
                    property: 'translateY',
                },
            ],
        },
    ];

    const enterViewVerticalSecond = [
        {
            start: "self",
            end: "self",
            endOffset: "50vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: 550,
                    endValue: 0,
                    property: 'translateY',
                },
            ],
        },
    ];

    const enterViewVerticalThird = [
        {
            start: "self",
            end: "self",
            endOffset: "50vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: 750,
                    endValue: 0,
                    property: 'translateY',
                },
            ],
        },
    ];

    const enterViewVerticalMobile = [
        {
            start: "self",
            end: "self",
            endOffset: "50vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: 100,
                    endValue: 0,
                    property: 'translateY',
                },
            ],
        },
    ];


    return (
        <>
            <section className='events-outer-container'>
                <div className='events-inner-container'>
                <AniLink
                    paintDrip
                    to="/events"
                    duration={1.5}
                    hex="#000000"
                    className='all-events'
                >
                    Alle events <ArrowRight className='arrow' />
                </AniLink>
                    <Plx
                        animateWhenNotInViewport={ true }
                        parallaxData={slideInDataHeader}
                    >
                        <div className='title-container'>
                            <h1>Events</h1>
                        </div>
                    </Plx>
                    <div className='images-container'>
                        <Plx
                            animateWhenNotInViewport={ true }
                            parallaxData={!breakpointMobile ? enterViewVertical : enterViewVerticalMobile}
                        >
                            <div className='outer-img-wrapper left-img'>
                                <EventContainerHp event={data.eventsSection.edges[0].node} />
                            </div>
                        </Plx>
                        <Plx    
                            animateWhenNotInViewport={ true }
                            parallaxData={!breakpointMobile ? enterViewVerticalSecond : enterViewVerticalMobile}
                        >
                            <div className='outer-img-wrapper middle-img'>
                                <EventContainerHp event={data.eventsSection.edges[1].node} />
                            </div>
                        </Plx>
                        <Plx
                            animateWhenNotInViewport={ true }
                            parallaxData={!breakpointMobile ? enterViewVerticalThird : enterViewVerticalMobile}
                        >
                            <div className='outer-img-wrapper right-img'>
                                <EventContainerHp event={data.eventsSection.edges[2].node} />
                            </div>
                        </Plx>
                    </div>
                    <AniLink
                    paintDrip
                    to="/events"
                    duration={1.5}
                    hex="#000000"
                    className="all-events"
                    >
                        Alle events <ArrowRight className='arrow' />
                    </AniLink>
                    <AniLink
                    paintDrip
                    to="/events"
                    duration={1.5}
                    hex="#000000"
                    className="hidden-button"
                    >
                        Alle events <ArrowRight className='arrow' />
                    </AniLink>
                </div>
            </section>
        </>
    )
}