import * as React from "react"
import './data-section.scss'
import { useStaticQuery, graphql } from "gatsby"
import { useViewport } from '../hooks/useViewPort'
import Plx from 'react-plx';


export default function DataSection() {
    const data = useStaticQuery(
        graphql`
        query DataSection {
            DataSection: wordPressAcfBlockStats {
                stats {
                    description
                    number
                    brands {
                        brand
                    }
                }
                id
            }
        }
    `
    )

    const [scrolled, setScrolled] = React.useState(false);

    //scroll behaviour
    React.useEffect(() => {
        const handleScroll = () => {
            let isScrolled = window.scrollY > 1300;
            if (isScrolled !== scrolled) {
                setScrolled(!scrolled);
            }
        };

        document.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [scrolled]);


    const { width } = useViewport();
    const breakpointMobile = width < 426;


    const slideFromLeftDataNum = [
        {
            start: "self",
            end: "self",
            endOffset: "50vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: -500,
                    endValue: -75,
                    property: 'translateX',
                },
            ],
        },
    ];

    const slideFromLeftDataNumMobile = [
        {
            start: "self",
            end: "self",
            endOffset: "50vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: -500,
                    endValue: 5,
                    property: 'translateX',
                },
            ],
        },
    ];

    const slideFromRightDataStatistics = [
        {
            start: "self",
            end: "self",
            endOffset: "50vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: 500,
                    endValue: 0,
                    property: 'translateX',
                },
            ],
        },
    ];



   


    return (
        <>
            <section className='data-section-outer-container'>
                <div className='data-section-inner-container'>
                    <div className='animation-wrapper'
                        data-active={scrolled}
                    >
                        
                            {
                                data.DataSection.stats.map((stat, index) => (
                                    <div className='data-line-container' key={index}>
                                        <div className='data-line-wrapper'></div>
                                        <div className='data-line'>
                                            {
                                                !breakpointMobile ?
                                                    <>
                                                        <Plx
                                                            parallaxData={slideFromLeftDataNum}
                                                        >
                                                        <div className="statistic-intro-container">
                                                            <div className='data-num'>{index + 1}.</div>
                                                            <div className='statistic'>
                                                                <p>{stat.number}</p>
                                                            </div>
                                                            <div className='intro-description'>
                                                                {stat.brands.map((brand, index) =>(
                                                                    <li key={index}>{brand.brand}</li>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        </Plx>
                                                        <Plx
                                                            parallaxData={slideFromRightDataStatistics}
                                                        >
                                                            <div className='description'>{stat.description}</div>
                                                        </Plx>
                                                    </>
                                                    :
                                                    <>
                                                        <Plx
                                                            parallaxData={slideFromLeftDataNumMobile}
                                                        >
                                                            <div className='num-description-container'>
                                                                <div className='data-num'>01.</div>
                                                                <div className='description'>{stat.description}</div>
                                                            </div>
                                                            <div className='statistic'>
                                                                <p>{stat.number}</p>
                                                            </div>
                                                            <div className='intro-description'>
                                                                {stat.brands.map((brand, index) =>(
                                                                    <li key={index}>{brand.brand}</li>
                                                                ))}
                                                            </div>
                                                        </Plx>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                            
                        </div>
                    </div>

            </section>

        </>
    )
} 