import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Hero from "../components/hero"
import InfoSection from "../components/info-section"
import DataSection from "../components/data-section"
import LocationSpecificsSection from "../components/location-specifics-section"
import HeroSection from "../components/hero-section"
import BuildingSpecificsSection from "../components/building-specifics-section"
import EventsSection from "../components/events-section"
import ContactSection from "../components/contact-section"






function IndexPage() {
  const data = useStaticQuery(
    graphql`
    query SneakBanner {
      SneakPeakBannerDesktop: wordPressAcfBlockImageText(
        header: {eq: "Banner Sneak Peek section"}
      ) {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        id
        header
        text
      }
      SneakPeakBannerMobile: wordPressAcfBlockImageText(
        header: {eq: "Banner Sneak Peek section"}
      ) {
        image_mobile {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        id
        header
        text
      }
    }
    `
  )

  const sources = [
    data.SneakPeakBannerMobile.image_mobile.localFile.childImageSharp.gatsbyImageData,
    {
      ...data.SneakPeakBannerDesktop.image.localFile.childImageSharp.gatsbyImageData,
      media: `(min-width: 426px)`,
    },
  ]


  return (
    <>
      <Layout>
        <Seo title="Home" />
        <Hero image={sources} alt={"Home Page Banner"} />
        <InfoSection />
        <DataSection />
        <LocationSpecificsSection />
        <HeroSection />
        <BuildingSpecificsSection />
        <EventsSection />
        <ContactSection />
      </Layout>
    </>
  )
}



export default IndexPage
