import * as React from "react"
import './hero-section.scss'
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import Plx from 'react-plx';


export default function HeroSection() {

    const data = useStaticQuery(
        graphql`
        query VideoSection {
            videoSection: wordPressAcfBlockImageText(
                header: {eq: "Video Section"}
              ) {
                id
                header
                text
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
              videoSectionMobile: wordPressAcfBlockImageText(
                header: {eq: "Video Section"}
              ) {
                image_mobile {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
        }
    `
    )

    const scaleImg = [
        {
            start: "self",
            end: "self",
            endOffset: "50vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: 1,
                    endValue: 1.1,
                    property: 'scale',
                },
            ],
        },
    ];

    return (
        <>
            <section className='hero-section-outer-container'>
                <div className='hero-section-inner-container'>
                    <div className="inner-wrapper">
                        <Plx
                            parallaxData={scaleImg}
                        >
                            <div className='video-wrapper'>
                                <GatsbyImage className="panoramicImg" image={data.videoSection.image.localFile.childImageSharp.gatsbyImageData} alt={data.videoSection.image.title} />
                                <GatsbyImage className="panoramicImgMobile" image={data.videoSectionMobile.image_mobile.localFile.childImageSharp.gatsbyImageData} alt={data.videoSection.image.title} />
                            </div>
                        </Plx>
                    </div>
                </div>
            </section>
        </>
    )
}