import * as React from "react"
import './building-specifics-section.scss'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useViewport } from '../hooks/useViewPort'
import Plx from 'react-plx';

export default function BuildingSpecificsSection() {

    const data = useStaticQuery(
        graphql`
        query BuildingSection {
            VideoSection: wordPressAcfBlockImageText(
              header: {eq: "Video Section"}
              ) {
                text
              }
              LeftBuildingSpecificsSection: wordPressAcfBlockImageText(
                header: {eq: "Left Building Specifics Section"}
              ) {
                id
                header
                text
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
              LeftBuildingSpecificsSectionMobile: wordPressAcfBlockImageText(
                header: {eq: "Left Building Specifics Section"}
              ) {
                image_mobile {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
              MiddleBuildingSpecificsSection: wordPressAcfBlockImageText(
                header: {eq: "Middle Building Specifics Section"}
              ) {
                id
                header
                text
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
              MiddleBuildingSpecificsSectionMobile: wordPressAcfBlockImageText(
                header: {eq: "Middle Building Specifics Section"}
              ) {
                image_mobile {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
              RightBuildingSpecificsSection: wordPressAcfBlockImageText(
                header: {eq: "Right Building Specifics Section"}
              ) {
                id
                header
                text
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
              RightBuildingSpecificsSectionMobile: wordPressAcfBlockImageText(
                header: {eq: "Right Building Specifics Section"}
              ) {
                image_mobile {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
        }
    `
    )



    const { width } = useViewport();
    const breakpointMobile = width < 426;

    const enterViewVertical = [
        {
            start: "self",
            end: "self",
            endOffset: "50vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: 350,
                    endValue: 0,
                    property: 'translateY',
                },
            ],
        },
    ];

    const enterViewVerticalMobile = [
        {
            start: "self",
            end: "self",
            endOffset: "25vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: 100,
                    endValue: 0,
                    property: 'translateY',
                },
            ],
        },
    ];


    return (
        <>
            <section className='building-specifics-outer-container'>
                <div className='building-specifics-inner-container'>
                    <Plx
                        animateWhenNotInViewport={true}
                        parallaxData={!breakpointMobile ? enterViewVertical : enterViewVerticalMobile}
                    >
                        <div className='top-section'>
                            <p>{data.VideoSection.text}</p>
                        </div>
                    </Plx>
                    <div className='bottom-section'>
                        <div className='left-img'>
                            <Plx
                                animateWhenNotInViewport={true}
                                parallaxData={!breakpointMobile ? enterViewVertical : enterViewVerticalMobile}
                            >
                                <div className='num-img-container'>
                                    <div className='img-container'>
                                        <div className='inner-image-container'>
                                            <GatsbyImage className="buildingImg" image={data.LeftBuildingSpecificsSection.image.localFile.childImageSharp.gatsbyImageData} alt={data.LeftBuildingSpecificsSection.image.title} />
                                            <GatsbyImage className="buildingImgMobile" image={data.LeftBuildingSpecificsSectionMobile.image_mobile.localFile.childImageSharp.gatsbyImageData} alt={data.LeftBuildingSpecificsSection.image.title} />
                                        </div>
                                        <p>{data.LeftBuildingSpecificsSection.text}</p>
                                    </div>
                                    <div className='img-num'>03.</div>
                                </div>
                            </Plx>
                        </div>
                        <div className='middle-img'>
                            <Plx
                                animateWhenNotInViewport={true}
                                parallaxData={!breakpointMobile ? enterViewVertical : enterViewVerticalMobile}
                            >
                                <div className='num-img-container'>
                                    <div className='img-container'>

                                        <div className='inner-image-container'>

                                        <GatsbyImage className="buildingImg" image={data.MiddleBuildingSpecificsSection.image.localFile.childImageSharp.gatsbyImageData} alt={data.MiddleBuildingSpecificsSection.image.title} />
                                            <GatsbyImage className="buildingImgMobile" image={data.MiddleBuildingSpecificsSectionMobile.image_mobile.localFile.childImageSharp.gatsbyImageData} alt={data.MiddleBuildingSpecificsSection.image.title} />

                                        </div>
                                        <p>{data.MiddleBuildingSpecificsSection.text}</p>
                                    </div>
                                    <div className='img-num'>04.</div>
                                </div>
                            </Plx>
                        </div>
                        <div className='right-img'>
                            <Plx
                                animateWhenNotInViewport={true}
                                parallaxData={!breakpointMobile ? enterViewVertical : enterViewVerticalMobile}
                            >
                                <div className='num-img-container'>
                                    <div className='img-container'>
                                        <div className='inner-image-container'>

                                        <GatsbyImage className="buildingImg" image={data.RightBuildingSpecificsSection.image.localFile.childImageSharp.gatsbyImageData} alt={data.RightBuildingSpecificsSection.image.title} />
                                            <GatsbyImage className="buildingImgMobile" image={data.RightBuildingSpecificsSectionMobile.image_mobile.localFile.childImageSharp.gatsbyImageData} alt={data.RightBuildingSpecificsSection.image.title} />

                                        </div>
                                        <p>{data.RightBuildingSpecificsSection.text}</p>
                                    </div>
                                    <div className='img-num'>05.</div>
                                </div>
                            </Plx>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}