import * as React from "react"
import './event-container-hp.scss'
import { GatsbyImage } from "gatsby-plugin-image"
import moment from "moment";
import Tilt from 'react-parallax-tilt';
import AniLink from "gatsby-plugin-transition-link/AniLink"


const EventContainerHp = ({ event }) => {
    
    const EventTime = () => {
        return (
            <>
                {
                    event.acf.koe_end_time !== "" ?
                        [event.acf.koe_start_time, event.acf.koe_end_time].join(' - ')
                        :
                        event.acf.koe_start_time
                }
            </>
        )
    }

    return (
        <AniLink
          paintDrip
          to="/events"
          duration={1.5}
          hex="#000000"
        >
            <div className="event-hp-link">
                <div className="event-card-hp">
                    <Tilt
                        tiltMaxAngleX={10}
                        tiltMaxAngleY={10}
                    >
                    <div className='top-caption'>
                        <p>
                            {moment(event.acf.koe_start_date).format("D MMM")}
                        </p>
                        <p>
                            {event.acf.koe_all_day_event ?
                                "ALL-DAY"
                                :
                                <EventTime />
                            }
                        </p>
                    </div>
                    <div className='img-wrapper'>
                        <GatsbyImage image={event.acf.koe_teaser_image.localFile.childImageSharp.gatsbyImageData} alt={event.acf.koe_teaser_text} />
                    </div>
                    </Tilt>
                    <div className='bottom-caption-container'>
                        <p>{event.acf.koe_event_name}</p>
                    </div>
                </div>
            </div>
        </AniLink>
    )
}

export default EventContainerHp