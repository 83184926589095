import * as React from "react"
import './info-section.scss'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useViewport } from '../hooks/useViewPort'
import Plx from 'react-plx';


export default function InfoSection() {

    const data = useStaticQuery(
        graphql`
        query InfoSection {
            InfoSection: wordPressAcfBlockImageText(
                header: {eq: "Info Section"}
              ) {
                id
                header
                text
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                  title
                }
              }
              InfoSectionMobile: wordPressAcfBlockImageText(
                header: {eq: "Info Section"}
              ) {
                image_mobile {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                  title
                }
              }
            FirstHeader: wordPressAcfBlockText(
                header: {eq: "First Header"}
            ) {
                header
                id
                text
            }
            SecondHeader: wordPressAcfBlockText(
                header: {eq: "Second Header"}
            ) {
                header
                id
                text
            }
            ThirdHeader: wordPressAcfBlockText(
                header: {eq: "Third Header"}
            ) {
                header
                id
                text
            }
            FourthHeader: wordPressAcfBlockText(
                header: {eq: "Forth Header"}
            ) {
                header
                id
                text
            }
            FifthHeader: wordPressAcfBlockText(
                header: {eq: "Fifth Header"}
            ) {
                header
                id
                text
            }
        }
    `
    )


    const { width } = useViewport();
    const breakpointMobile = width < 426;

    const slideInDataHeader = [
        {
            start: "self",
            end: "self",
            endOffset: "50vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: 0,
                    endValue: 930,
                    property: 'translateX',
                },
            ],
        },
    ];

    const slideInDataHeaderMobile = [
        {
            start: "self",
            end: "self",
            endOffset: "50vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: 0,
                    endValue: 510,
                    property: 'translateX',
                },
            ],
        },
    ];

    const enterViewVertical = [
        {
            start: "self",
            end: "self",
            endOffset: "50vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: 350,
                    endValue: 0,
                    property: 'translateY',
                },
            ],
        },
    ];

    const enterViewVerticalMobile = [
        {
            start: "self",
            end: "self",
            endOffset: "25vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: 100,
                    endValue: 0,
                    property: 'translateY',
                },
            ],
        },
    ];



    return (
        <>
            <section className='info-container'>
                <div className='inner-info-container'>
                    <div className='animation-wrapper'>
                        <div className='text-container'>
                            <div className='top-text'>
                                <Plx
                                    animateWhenNotInViewport={true}
                                    parallaxData={!breakpointMobile ? slideInDataHeader : slideInDataHeaderMobile}
                                >
                                    <h1>{data.FirstHeader.text}</h1>
                                </Plx>
                                <Plx
                                    animateWhenNotInViewport={true}
                                    parallaxData={!breakpointMobile ? slideInDataHeader : slideInDataHeaderMobile}
                                >
                                    <h1>{data.SecondHeader.text}</h1>
                                </Plx>
                                <Plx
                                    animateWhenNotInViewport={true}
                                    parallaxData={!breakpointMobile ? slideInDataHeader : slideInDataHeaderMobile}
                                >
                                    <h1>{data.ThirdHeader.text}</h1>
                                </Plx>
                                <Plx
                                    animateWhenNotInViewport={true}
                                    parallaxData={!breakpointMobile ? slideInDataHeader : slideInDataHeaderMobile}
                                >
                                    <h1>{data.FourthHeader.text}</h1>
                                </Plx>
                                <Plx
                                    animateWhenNotInViewport={true}
                                    parallaxData={!breakpointMobile ? slideInDataHeader : slideInDataHeaderMobile}
                                >
                                    <h1>{data.FifthHeader.text}</h1>
                                </Plx>
                            </div>
                            <Plx
                                animateWhenNotInViewport={true}
                                parallaxData={!breakpointMobile ? enterViewVertical : enterViewVerticalMobile}
                            >
                                <div className='bottom-text'
                                    dangerouslySetInnerHTML={{ __html: data.InfoSection.text }}
                                />
                            </Plx>
                        </div>
                        <Plx
                            animateWhenNotInViewport={true}
                            parallaxData={!breakpointMobile ? enterViewVertical : enterViewVerticalMobile}
                            className="circle-container"
                        >
                            <div className='img-container'>
                                <GatsbyImage className="circle" image={data.InfoSection.image.localFile.childImageSharp.gatsbyImageData} alt={data.InfoSection.image.title} />
                                <GatsbyImage className="circle-mobile" image={data.InfoSectionMobile.image_mobile.localFile.childImageSharp.gatsbyImageData} alt={data.InfoSection.image.title} />
                            </div>
                        </Plx>
                    </div>
                </div>
            </section>
        </>
    )
}